import React, { useEffect, useRef, useState } from "react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./HomePageSlider.css";
import { Swiper } from "swiper/react";
import slideImg from "../../../assets/img/pexels-justus-menke-5213528.webp";
import slideImg2 from "../../../assets/img/blog-header1.webp";
import slideImg3 from "../../../assets/img/offies.webp";
import { Container, Row } from "react-bootstrap";

const HomePageSlider = () => {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}`;
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const updateScreenSize = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  if (screenWidth < 640) {
    return (
      <section
        id="hero"
        className="section hero d-flex align-items-center"
        style={{ direction: "rtl" }}
      >
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          loop={false}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper col-9 mobile_swiper"
        >
          <SwiperSlide>
            <Container>
              <Row>
                <div className="text-col " style={{ direction: "rtl" }}>
                  <h2 data-aos="fade-up">جودي للاستثمار</h2>

                  <p
                    style={{
                      textAlign: "justify",
                      color: "#fff",
                    }}
                  >
                    شركة متخصصة بإدارة الاستثمار في السوق التركي ، وتأسيس
                    الشركات ، وتقديم الاستشارات المالية والقانونية والتكنولوجية
                    للشركات والأفراد , تأسست الشركة عام 2013 كشركة متخصصة
                    باستيراد وتصدير المنتجات الإلكترونية ، وفي عام 2014 تحولت
                    لشركة تعمل على إبداع الأفكار وتحويلها لمشاريع ومن ثم لشركات
                    مساهمة وطرحها للاستثمار، وتعتبر شركة NOONTEK المتخصصة
                    بالتكنولوجيا أول شركة مساهمة تم إطلاقها عام 2014 , وفي عام
                    2020 تحولت الشركة للاستثمار المؤسساتي بتأسيس صناديق
                    الاستثمار , والحصول على الاستثمارات من خارج تركيا.
                  </p>
                </div>
              </Row>
            </Container>
          </SwiperSlide>
          <SwiperSlide>
            <Container>
              <Row>
                <div className="text-col" style={{ direction: "rtl" }}>
                  <h2 data-aos="fade-up">استشارات استثمارية</h2>
                  <p
                    style={{
                      textAlign: "justify",
                      color: "#fff",
                    }}
                  >
                    نساعد المستثمرين الذين يتطلعون إلى تأسيس أعمالهم على الأراضي
                    التركية في تحديد الترخيص المناسب لأعمالهم واختيار المدن
                    المناسبة لهم، وضبط أعمالهم المالية والقانونية والمساعدة
                    بتنفيذ مشاريعهم. كما نطور لهم آلية العلاقة بين الشركاء،
                    وتجهيز شركاتهم لتصبح صالحة لاستقبال الاستثمارات من الأفراد
                    والمؤسسات.
                  </p>
                </div>
              </Row>
            </Container>
          </SwiperSlide>
          <SwiperSlide>
            <Container>
              <Row>
                <div className="text-col" style={{ direction: "rtl" }}>
                  <h2 data-aos="fade-up">تأسيس المشاريع الاستثمارية</h2>

                  <p
                    style={{
                      textAlign: "justify",
                      color: "#fff",
                    }}
                  >
                    تعتبر شركة جودي للاستثمار شركة رائدة بتأسيس المشاريع
                    الاستثمارية وتحويلها لشركات مساهمة وطرحها للاستثمار للأفراد
                    والمؤسسات. هذه الخدمة تمكن المستثمرين من البدء في ممارسة
                    النشاط التجاري وتأسيس شركات مساهمة، وتحقيق أهداف اقتصادية
                    كبيرة حيث تتيح لهم الفرصة لبناء رأس المال والاستفادة من
                    توزيع الأرباح بناءً على حصص المساهمة.
                  </p>
                </div>
              </Row>
            </Container>
          </SwiperSlide>
        </Swiper>
      </section>
    );
  } else {
    return (
      <div className="Home_Slider">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 25000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          loop={true}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          onAutoplayTimeLeft={onAutoplayTimeLeft}
          className="mySwiper col-9"
        >
          <SwiperSlide>
            <div className="Home_img col-8">
              <div className="slide_overlay"></div>
              <img src={slideImg} alt="" />
            </div>
            <div className="Home_text col-5">
              <div className="textt">
                <h1>جودي للاستثمار</h1>
                <p>
                  شركة متخصصة بإدارة الاستثمار في السوق التركي ، وتأسيس الشركات
                  ، وتقديم الاستشارات المالية والقانونية والتكنولوجية للشركات
                  والأفراد , تأسست الشركة عام 2013 كشركة متخصصة باستيراد وتصدير
                  المنتجات الإلكترونية ، وفي عام 2014 تحولت لشركة تعمل على إبداع
                  الأفكار وتحويلها لمشاريع ومن ثم لشركات مساهمة وطرحها
                  للاستثمار، وتعتبر شركة NOONTEK المتخصصة بالتكنولوجيا أول شركة
                  مساهمة تم إطلاقها عام 2014 , وفي عام 2020 تحولت الشركة
                  للاستثمار المؤسساتي بتأسيس صناديق الاستثمار , والحصول على
                  الاستثمارات من خارج تركيا.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="Home_img col-8">
              <div className="slide_overlay"></div>
              <img src={slideImg2} alt="" />
            </div>
            <div className="Home_text col-5">
              <div className="textt">
                <h1>استشارات استثمارية</h1>
                <p>
                  نساعد المستثمرين الذين يتطلعون إلى تأسيس أعمالهم على الأراضي
                  التركية في تحديد الترخيص المناسب لأعمالهم واختيار المدن
                  المناسبة لهم، وضبط أعمالهم المالية والقانونية والمساعدة بتنفيذ
                  مشاريعهم. كما نطور لهم آلية العلاقة بين الشركاء، وتجهيز
                  شركاتهم لتصبح صالحة لاستقبال الاستثمارات من الأفراد والمؤسسات.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="Home_img col-8">
              <div className="slide_overlay"></div>
              <img src={slideImg3} alt="" />
            </div>
            <div className="Home_text col-5">
              <div className="textt">
                <h1>تأسيس المشاريع الاستثمارية</h1>
                <p>
                  تعتبر شركة جودي للاستثمار شركة رائدة بتأسيس المشاريع
                  الاستثمارية وتحويلها لشركات مساهمة وطرحها للاستثمار للأفراد
                  والمؤسسات. هذه الخدمة تمكن المستثمرين من البدء في ممارسة
                  النشاط التجاري وتأسيس شركات مساهمة، وتحقيق أهداف اقتصادية
                  كبيرة حيث تتيح لهم الفرصة لبناء رأس المال والاستفادة من توزيع
                  الأرباح بناءً على حصص المساهمة.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <div className="autoplay-progress" slot="container-end">
            <svg viewBox="0 0 48 48" ref={progressCircle}>
              <circle cx="24" cy="24" r="20"></circle>
            </svg>
            <span ref={progressContent}></span>
          </div>
        </Swiper>
      </div>
    );
  }
};
export default HomePageSlider;
